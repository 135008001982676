import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Login from "./pages/login/login";
import OTP from "./pages/otp/otp";
import DashboardLayout from "./components/DashboardLayout/DashboardLayout";
import Home from "./pages/home/home";
import { IntercomProvider, useIntercom } from "react-use-intercom";

const App = () => {
  const intercomAppId = "bkktwawu";
  const [user, setUser] = useState(null); // Store user data

  const handleLogin = (userData) => {
    setUser(userData);
  };

  return (
    <IntercomProvider appId={intercomAppId} autoBoot>
      <Router>
        <Routes>
          <Route path="/" element={<Home onLogin={handleLogin} />} />
          <Route path="/login" element={<Login onLogin={handleLogin} />} />
          <Route path="/otp" element={<OTP onLogin={handleLogin} />} />
          <Route
            path="/dashboard/*"
            element={<PrivateRoute component={DashboardLayout} />}
          />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
      {user && <IntercomUser user={user} />} {/* Identify user with Intercom */}
    </IntercomProvider>
  );
};

const PrivateRoute = ({ component: Component }) => {
  const isAuthenticated = localStorage.getItem("userData");
  return isAuthenticated ? <Component /> : <Navigate to="/login" />;
};

const IntercomUser = ({ user }) => {
  const { boot } = useIntercom();

  useEffect(() => {
    // Identify the user with Intercom after logging in
    if (user) {
      boot({
        userId: user.intercomUserId,
        userHash: user.intercomHmac,
        name: `${user.firstName} ${user.lastName}`, // Optional user details
        email: user.email, // Optional user details
      });
    }
  }, [user, boot]);

  return null;
};

export default App;
