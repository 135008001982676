import React, { useEffect, useState } from "react";
import "./login.scss";
import { Form, Button, Alert } from "react-bootstrap";
import Logo from "../../assets/icons/logo.svg";
import { fetchData } from "../../utils/fetchData";
import { useNavigate } from "react-router-dom";
import { useIntercom } from "react-use-intercom"; // Import useIntercom hook

const Login = () => {
  const [inputPhone, setInputPhone] = useState(""); // State for phone number
  const [loading, setLoading] = useState(false);
  const [showErr, setShowErr] = useState(false);
  const [errMessage, setErrMessage] = useState("");
  const navigate = useNavigate();
  const { show } = useIntercom(); // Use the Intercom hook to get the show function

  const [userData] = useState(() => {
    const savedUserData = localStorage.getItem("userData");
    return savedUserData ? JSON.parse(savedUserData) : null; // Handle case where userData might not be present
  });

  useEffect(() => {
    if (userData?.token) {
      navigate("/dashboard");
    }
  }, []);

  const handleSendOTP = (event) => {
    setLoading(true);
    event.preventDefault();

    // Prepare the data for sending OTP using existing data structure
    let dataObj = {
      request: "organizers/send-otp",
      phoneNumber: inputPhone,
    };

    fetchData(dataObj, "POST")
      .then((response) => {
        setLoading(false);
        setShowErr(false);
        navigate("/otp", { state: { phoneNumber: inputPhone } });
      })
      .catch((error) => {
        setErrMessage(error.message);
        setShowErr(true);
        setLoading(false);
      });
  };

  // Function to open the Intercom chat
  const handleOpenIntercom = () => {
    show(); // Open Intercom Messenger
  };

  return (
    <div className="login-page">
      <div className="login__wrapper">
        <Form
          className="shadow p-4 bg-white rounded login__form"
          onSubmit={handleSendOTP}
        >
          <img
            className="img-thumbnail mx-auto d-block mb-2 login__form__logo"
            src={Logo}
            alt="logo"
          />
          <div className="h4 mb-2 text-center">התחברות למערכת</div>
          {showErr && <Alert variant="danger">{errMessage}</Alert>}
          <Form.Group className="mb-2" controlId="phone">
            <Form.Label>מספר נייד</Form.Label>
            <Form.Control
              type="tel"
              value={inputPhone}
              placeholder="הכנס את מספר הנייד שלך"
              onChange={(e) => setInputPhone(e.target.value)}
              required
              maxLength={10}
              style={{ textAlign: "right" }}
            />
          </Form.Group>

          {!loading ? (
            <Button
              className="w-100 mt-4 table-btn"
              variant="primary"
              type="submit"
            >
              שלח קוד
            </Button>
          ) : (
            <Button
              className="w-100 mt-4 table-btn"
              variant="primary"
              type="submit"
              disabled
            >
              רק רגע...
            </Button>
          )}
          <div className="d-grid justify-content-end">
            <Button
              className="text-muted px-0"
              variant="link"
              onClick={handleOpenIntercom} // Open Intercom chat when clicked
            >
              צריך עזרה?
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default Login;
