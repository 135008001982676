import React from "react";
import { useLocation } from "react-router-dom";
import { convertLineBreaks } from "../../utils/supportFunctions";

const TripDetails = () => {
  const location = useLocation();
  const { trip } = location.state; // Get trip data from the state
  console.log(trip);
  const [startDate, startTime] = trip.orderStartTime.split(" ");
  const [endDate, endTime] = trip.orderEndTime.split(" ");

  return (
    <div className="container mt-4">
      <h1 className="font-weight-bold">{trip.lineDescription}</h1>
      <div className="card shadow-sm">
        <div className="card-body">
          <p className="card-text">
            <strong>שעות:</strong> {endTime} - {startTime}
          </p>
          <p className="card-text">
            <strong>סוג הרכב:</strong> {trip.orderCarType}
          </p>
          {trip.shortRemarks || trip.longRemarks ? (
            <p className="card-text m-0 p-0 font-weight-bold">
              <strong>הערות:</strong>
            </p>
          ) : null}
          {trip.shortRemarks && (
            <p className="card-text">{trip.shortRemarks}</p>
          )}
          {trip.longRemarks && (
            <>
              {console.log("Trip Remarks:", trip.longRemarks)}{" "}
              {/* Debugging line */}
              <p
                className="card-text m-0 p-0"
                dangerouslySetInnerHTML={{
                  __html: convertLineBreaks(trip.longRemarks),
                }}
              ></p>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default TripDetails;
