import React, { useState, useEffect } from "react";
import { Navbar, Nav, Button, Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/icons/logo.svg";
import { fetchData } from "../../utils/fetchData";
import "./topmenu.scss";

const TopNavbar = () => {
  const [greeting, setGreeting] = useState("");
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const hours = new Date().getHours();
    if (hours < 12) {
      setGreeting("בוקר טוב");
    } else if (hours < 18) {
      setGreeting("צהריים טובים");
    } else {
      setGreeting("ערב טוב");
    }
  }, []);

  const handleLogout = (event) => {
    setLoading(true);
    event.preventDefault();

    // Prepare the data for sending OTP using existing data structure
    let dataObj = {
      request: "organizers/logout",
    };

    fetchData(dataObj, "POST")
      .then((response) => {
        console.log("Logout successful");
        // Clear the token from local storage or any other storage mechanism you use
        localStorage.removeItem("userData");
        setLoading(false);
        navigate("/login");
      })
      .catch((error) => {
        console.log("Logout successful");
        // Clear the token from local storage or any other storage mechanism you use
        localStorage.removeItem("userData");
        setLoading(false);
        navigate("/login");
      });
  };

  return (
    <Navbar
      variant="light"
      expand="lg"
      sticky="top"
      className="shadow-sm px-3 navbar"
    >
      <Navbar.Brand href="#">
        <img
          src={Logo}
          alt="Logo"
          width="200"
          height="60"
          className="d-inline-block align-top"
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ms-auto">
          <Nav.Item>
            <Button variant="outline-danger" onClick={handleLogout}>
              התנתק
            </Button>
          </Nav.Item>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default TopNavbar;
