import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import "./dashboardLayout.scss";
import "../../sass/base/_base.scss";
import TripList from "../../pages/trips/trips";
import TripDetails from "../../pages/trips/TripDetails";
import TopNavbar from "../TopNavbar/TopNavbar";

const DashboardLayout = () => {
  const location = useLocation();
  const isScreenPreview = location.pathname.includes("/clients/");

  return (
    <div>
      <Container fluid>
        <Row>
          <TopNavbar />
          <Col
            md={!isScreenPreview ? 12 : 10}
            className={isScreenPreview ? "p-0 m-0" : "mt-3"}
          >
            <Routes>
              <Route path="/" element={<TripList />} />
              <Route path="*" element={<Navigate to="/" />} />
              <Route path="/trip-details" element={<TripDetails />} />
            </Routes>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default DashboardLayout;
