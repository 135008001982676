import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { MdFlag, MdPerson, MdSearchOff, MdSync } from "react-icons/md";
import { carTypes, formatNumberWithCommas } from "../../utils/supportFunctions";
import { fetchData } from "../../utils/fetchData";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./trips.scss";

const formatDate = (dateString) => {
  const [year, month, day] = dateString.split("-");
  return `${day}.${month}.${year}`;
};

// Function to group trips by date and then by starting hour
const groupTripsByDateAndHour = (trips) => {
  const groupedTrips = {};

  // Sort trips by date and then by starting time (hour and minutes)
  trips.sort((a, b) => {
    const [dateA, timeA] = a.orderStartTime.split(" ");
    const [dateB, timeB] = b.orderStartTime.split(" ");

    if (dateA === dateB) {
      return timeA.localeCompare(timeB);
    }
    return dateA.localeCompare(dateB);
  });

  // Group trips by date and then by hour
  trips.forEach((trip) => {
    const [date, startTime] = trip.orderStartTime.split(" ");
    const hour = parseInt(startTime.split(":")[0]);

    if (!groupedTrips[date]) {
      groupedTrips[date] = {};
    }

    if (!groupedTrips[date][hour]) {
      groupedTrips[date][hour] = [];
    }

    groupedTrips[date][hour].push(trip);
  });

  // Sort the dates and hours to ensure correct order
  const sortedDates = Object.keys(groupedTrips).sort((a, b) =>
    a.localeCompare(b)
  );
  const sortedGroupedTrips = {};

  sortedDates.forEach((date) => {
    sortedGroupedTrips[date] = {};
    const sortedHours = Object.keys(groupedTrips[date]).sort(
      (a, b) => parseInt(a) - parseInt(b)
    );
    sortedHours.forEach((hour) => {
      sortedGroupedTrips[date][hour] = groupedTrips[date][hour];
    });
  });

  return sortedGroupedTrips;
};

const TripList = () => {
  const [trips, setTrips] = useState([]);
  const [filteredTrips, setFilteredTrips] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [stationFilter, setStationFilter] = useState("all");
  const [carTypeFilter, setCarTypeFilter] = useState("all");
  const [loading, setLoading] = useState(true);
  const [showErr, setShowErr] = useState(false);
  const [errMessage, setErrMessage] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [lastFetchedDates, setLastFetchedDates] = useState({
    startDate: null,
    endDate: null,
  });
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const navigate = useNavigate();
  const [userData] = useState(() => {
    const savedUserData = localStorage.getItem("userData");
    return savedUserData ? JSON.parse(savedUserData) : null;
  });

  const [isDebouncing, setIsDebouncing] = useState(false);

  const filterTrips = useCallback(() => {
    const filtered = trips.filter((trip) => {
      const matchesSearchTerm =
        trip.lineDescription.toLowerCase().includes(searchTerm.toLowerCase()) ||
        formatDate(trip.orderStartTime.split(" ")[0]).includes(searchTerm);

      const matchesStationFilter =
        stationFilter === "all" ||
        (stationFilter === "atLeast1" && trip.stations.length >= 1) ||
        (stationFilter === "lessThan1" && trip.stations.length < 1);

      const matchesCarTypeFilter =
        carTypeFilter === "all" || trip.orderCarType === carTypeFilter;

      const tripDate = new Date(trip.orderStartTime.split(" ")[0]);
      const matchesDateRange =
        (!startDate || tripDate >= startDate) &&
        (!endDate || tripDate <= endDate);

      return (
        matchesSearchTerm &&
        matchesStationFilter &&
        matchesCarTypeFilter &&
        matchesDateRange
      );
    });

    setFilteredTrips(filtered);
    setLoading(false);
  }, [searchTerm, stationFilter, carTypeFilter, trips, startDate, endDate]);

  const getTrips = async (fromDate, toDate) => {
    setLoading(true);

    let dataObj = {
      request: "trips",
      fromDateTime: fromDate,
      toDateTime: toDate,
    };

    fetchData(dataObj, "POST")
      .then((response) => {
        setLoading(false);
        setShowErr(false);
        setTrips(response.data.trips);
        setFilteredTrips(response.data.trips); // Initialize filteredTrips with fetched data
      })
      .catch((error) => {
        setErrMessage(error.message);
        setShowErr(true);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (isFirstLoad) {
      // Initial load: fetch trips from server
      getTrips("", "");
      setIsFirstLoad(false);
    }
  }, [isFirstLoad]);

  const handleRefreshData = () => {
    const isDateChanged =
      startDate !== lastFetchedDates.startDate ||
      endDate !== lastFetchedDates.endDate;

    if (isDateChanged) {
      const fromDate = startDate
        ? new Date(startDate.getTime() - startDate.getTimezoneOffset() * 60000)
            .toISOString()
            .split("T")[0]
        : "";
      const toDate = endDate
        ? new Date(endDate.getTime() - endDate.getTimezoneOffset() * 60000)
            .toISOString()
            .split("T")[0]
        : "";

      getTrips(fromDate, toDate).then(() => {
        // After fetching new trips, apply search filter if needed
        if (
          searchTerm.trim() !== "" ||
          stationFilter !== "all" ||
          carTypeFilter !== "all"
        ) {
          filterTrips();
        }
      });
      setLastFetchedDates({ startDate, endDate });
    } else {
      // If filters have changed or searchTerm is not empty, apply the filters
      setIsDebouncing(true);
      setTimeout(() => {
        filterTrips();
        setIsDebouncing(false);
      }, 500); // Simulate network delay
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleStationFilterChange = (event) => {
    setStationFilter(event.target.value);
  };

  const handleCarTypeFilterChange = (event) => {
    setCarTypeFilter(event.target.value);
  };

  const handleTripClick = (trip) => {
    navigate("/dashboard/trip-details", { state: { trip } });
  };

  const groupedTrips = groupTripsByDateAndHour(filteredTrips);

  return (
    <div className="container mt-4">
      <div className="d-flex gap-3 align-items-center pb-4">
        <img
          src={userData.clientProfileImage}
          alt="img"
          className="img-fluid rounded-circle"
          style={{ width: "4rem" }}
        />
        <div>
          <h3 className="text-lg font-weight-bold m-0 p-0">
            היי {userData.firstName},
          </h3>
          <span className="text-lg m-0 p-0">{userData.clientName}</span>
        </div>
      </div>
      {showErr && <div className="alert alert-danger">{errMessage}</div>}

      {/* Search and Filter Inputs */}
      <div className="row mb-3 g-2">
        <div className="col-12 col-md-12">
          <input
            type="text"
            className="form-control"
            placeholder="חפש לפי מלל או עיר לדוגמא: תל אביב"
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </div>
      </div>
      <div className="row mb-3 g-2">
        <div className="col-10 col-md-10">
          <div className="row mb-3 g-2">
            <div className="col-3 col-md-3">
              <select
                className="form-select"
                value={stationFilter}
                onChange={handleStationFilterChange}
              >
                <option value="all">כל הנסיעות</option>
                <option value="atLeast1">כולל תחנות</option>
                <option value="lessThan1">לא כולל תחנות</option>
              </select>
            </div>
            <div className="col-3 col-md-3">
              <select
                className="form-select"
                value={carTypeFilter}
                onChange={handleCarTypeFilterChange}
              >
                {carTypes.map((carType) => (
                  <option key={carType.value} value={carType.value}>
                    {carType.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-3 col-md-3">
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                dateFormat="dd/MM/yyyy"
                className="form-control custom-datepicker"
                placeholderText="תאריך התחלה"
                isClearable
              />
            </div>
            <div className="col-3 col-md-3">
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                dateFormat="dd/MM/yyyy"
                className="form-control custom-datepicker w-100"
                placeholderText="תאריך סיום"
                isClearable
              />
            </div>
          </div>
        </div>
        <div className="col-2 col-md-2">
          <button
            className="btn btn-primary w-100 table-btn"
            onClick={handleRefreshData}
          >
            רענן
          </button>
        </div>
      </div>

      {loading || isDebouncing ? (
        <div className="position-relative mt-5">
          <div
            className="d-flex justify-content-center align-items-center position-absolute w-100 h-100 bg-white"
            style={{ top: 0, left: 0, zIndex: 10, opacity: 0.8 }}
          >
            <MdSync size={60} className="text-primary animate-spin" />
          </div>
        </div>
      ) : filteredTrips.length === 0 ? (
        <div className="text-center mt-5">
          <MdSearchOff size={60} className="text-muted mb-3" />
          <p className="text-muted">לא נמצאו נסיעות</p>
        </div>
      ) : (
        <div className="row">
          {Object.keys(groupedTrips).map((date) => (
            <div key={date} className="col-12 mb-4">
              <div className="d-flex align-items-center">
                <hr className="flex-grow-1" />
                <h2 className="mx-3 font-weight-bold text-primary">
                  {formatDate(date)}
                </h2>
                <hr className="flex-grow-1" />
              </div>
              {Object.keys(groupedTrips[date]).map((hour) => (
                <div key={hour} className="col-12 mb-4">
                  <h5 className="font-weight-bold">
                    {hour.padStart(2, "0")}:00
                  </h5>
                  {/* Time title */}
                  <div className="row">
                    {groupedTrips[date][hour].map((trip, index) => {
                      const [startDate, startTime] =
                        trip.orderStartTime.split(" ");
                      const formattedDate = formatDate(startDate);

                      const [endDate, endTime] = trip.orderEndTime.split(" ");
                      const formattedEndDate = formatDate(endDate);

                      const totalPassengers = trip.stations.reduce(
                        (total, station) => total + station.passengers.length,
                        0
                      );

                      return (
                        <div
                          key={index}
                          className="col-md-4 mb-4"
                          onClick={() => handleTripClick(trip)}
                        >
                          <div
                            className="card card-trip shadow-sm h-100 cursor-pointer"
                            role="button"
                          >
                            <div className="card-body d-flex justify-content-between flex-column">
                              <h5 className="card-title font-weight-medium">
                                {trip.lineDescription}
                              </h5>
                              <div className="d-flex flex-column justify-content-end">
                                {trip.orderCarType && (
                                  <p className="card-text m-0 p-0">
                                    <strong className="font-weight-medium">
                                      סוג הרכב:
                                    </strong>{" "}
                                    {trip.orderCarType}
                                  </p>
                                )}
                                <p className="card-text m-0 p-0">
                                  <strong className="font-weight-medium">
                                    תאריך:
                                  </strong>{" "}
                                  {formattedDate}
                                </p>
                                <p className="card-text m-0 p-0">
                                  <strong className="font-weight-medium">
                                    שעות:
                                  </strong>{" "}
                                  {endTime} - {startTime}
                                </p>
                                <div>
                                  {trip.driverPrice > 0 && (
                                    <div className="text-primary">
                                      <strong className="font-weight-bold text-primary">
                                        מחיר:
                                      </strong>{" "}
                                      <span className="text-sm font-weight-bold">
                                        ₪
                                      </span>
                                      <span className="font-weight-black m-0 p-0 text-primary lead">
                                        {formatNumberWithCommas(
                                          trip.driverPrice
                                        )}
                                      </span>
                                    </div>
                                  )}
                                </div>
                                <div className="card-text d-flex justify-content-end align-items-center gap-2">
                                  {totalPassengers > 0 ? (
                                    <p className="card-text d-flex justify-content-center align-items-center m-0 p-0">
                                      <MdPerson />
                                      {totalPassengers}
                                    </p>
                                  ) : (
                                    <p></p>
                                  )}
                                  {trip.stations.length > 0 ? (
                                    <p className="card-text d-flex justify-content-center align-items-center m-0 p-0">
                                      <MdFlag />
                                      {trip.stations.length}
                                    </p>
                                  ) : (
                                    <p></p>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default TripList;
