// src/pages/home/Home.jsx
import React from "react";
import "./home.scss";

import wmainimg from "../../assets/images/wmainimg.png";

const Home = () => {
  return (
    <>
      <section className="roadmaps  roadmapssecmobimg p-0">
        <span className="bannershade"></span>
        <img src={wmainimg} alt="img" className="wmainimg" />
        <div className="maindivcomg ">
          <img
            src="\wez-logo.png"
            alt="img"
            className="img-fluid  mobileviewneww"
          />
          <h1 className="comingtext">Coming soon</h1>
        </div>
      </section>
    </>
  );
};

export default Home;
